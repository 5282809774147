.page {
  margin-bottom: 8em;
}

.page--content {
  margin: 0px 96px;
  margin-top: 96px;
}

.page--content {
  margin-top: 480px;
  min-height: 25vh;
}

.page--with-addition .page--content {
  margin-top: 620px;
}

div[role="article"] {
  max-width: 820px;
  width: 100%;
}

@media (max-width: 1024px) {
  .page--content {
    margin: 0px 48px;
    margin-top: 48px;
  }

  .page--content {
    margin-top: 256px;
  }
}

@media (max-width: 667px) {
  .page--content {
    margin: 0px 24px;
    margin-top: 24px;
  }

  .page--content {
    margin-top: 172px;
  }
}

@media (max-width: 414px) {
  .page--with-addition .page--content {
    margin-top: 420px;
  }
}
