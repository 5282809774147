.caption {
  border-bottom: 2px #3d278c solid;
  font-size: 12pt;
  font-weight: 400;
  margin-top: 6px;
  margin-right: 12px;
  transition: 0.2s;
  display: inline-block;
}

.caption--green,
.caption--green:hover {
  border-bottom: 2px #04d98b solid;
}

.caption:hover {
  border-bottom: 2px #5321bf solid;
}
