/* Variables */
/* --black-1: #0d0d0d; */
/* --blue-1: #3d278c; */
/* --blue-2: #5321bf */
/* --green-1: #04d98b; */
/* --gray-1: #f2f2f2; */

/* Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  color: #0d0d0d;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Style */
h2 {
  font-size: 48pt;
  color: #3d278c;
  font-weight: 600;
}

h3 {
  background-color: #3d278c;
  color: white;
  padding: 8px 12px;
  margin-top: 48px;
  margin-bottom: 24px;
  line-height: 1.5em;
  font-weight: 300;
  font-size: 14pt;
}

h4 {
  font-size: 14pt;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

p {
  margin-bottom: 36px;
  line-height: 1.5em;
  font-weight: 300;
  font-size: 14pt;
}

a {
  text-decoration: none;
  border-bottom: 2px #04d98b solid;
  transition: 0.2s;
}

a:hover {
  color: #5321bf;
  border-bottom: 2px #5321bf solid;
}

*::selection {
  background: #3d278c;
  color: white;
}

*::-moz-selection {
  background: #3d278c;
  color: white;
}

.detail {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (max-width: 1420px) {
  h2 {
    font-size: 36pt;
  }
}

@media (max-width: 1024px) {
  h2 {
    font-size: 24pt;
  }
}

@media (max-width: 667px) {
  h2 {
    font-size: 18pt;
  }
}

.page__article__image {
  width: 100%;
  max-width: 256px;
}

@media (min-width: 1025px) {
  .page__article__image {
    display: none;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex > * {
  margin-right: 4em;
  margin-bottom: 2em;
}

.button {
  background-color: #3d278c;
  text-decoration: none;
  border: none;
  color: white;
  padding: 1em 2em;
  font-weight: 700;
}

.button:hover {
  color: white;
  background-color: #5321bf;
  border: none;
}
