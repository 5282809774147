.header {
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 96px 96px 0px 96px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.6);
}

@supports not (backdrop-filter: blur(4px)) {
  .header {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 8px 8px rgba(255, 255, 255, 0.9);
  }
}

.header--sticky {
  position: fixed;
  top: calc(-1 * (96px + 52px));
  top: -158px;
}

.header h1 {
  transition: 0.2s;
}

.header__page-title {
  margin: 96px 128px 96px 0px;
}

.header--sticky.header--addition h1 {
  font-size: 24pt;
}

.header--sticky.header--addition .header__page-title {
  margin: 96px 128px 48px 0px;
}

.header .header__logo {
  opacity: 1;
  transition: 0.2s;
  height: 52px;
}

.header a {
  border: none;
}

.header--sticky .header__logo {
  opacity: 0;
}

.header__page-title {
  display: flex;
  justify-content: space-between;
}

.header__image img {
  position: absolute;
  width: 20%;
  max-width: 320px;
  margin-left: -20%;
}

@media (max-width: 1024px) {
  .header .header__page-title,
  .header--sticky.header--addition .header__page-title {
    margin: 48px 96px 48px 0px;
  }

  .header {
    padding: 48px 48px 0px 48px;
  }

  .header--sticky {
    top: calc(-1 * (48px + 48px));
    top: -96px;
  }

  .header .header__logo {
    height: 48px;
  }

  .header__image img {
    display: none;
  }
}

@media (max-width: 667px) {
  .header h1,
  .header--sticky.header--addition h1 {
    font-size: 18pt;
  }

  .header .header__page-title,
  .header--sticky.header--addition .header__page-title {
    margin: 24px 48px 24px 0px;
  }

  .header {
    padding: 24px 24px 0px 24px;
  }

  .header--sticky {
    top: calc(-1 * (24px + 36px));
    top: -60px;
  }

  .header--sticky.header--addition {
    top: calc(-1 * (24px + 36px + 24px + 24px));
    top: -108px;
  }

  .header .header__logo {
    height: 36px;
  }
}
