.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 72px 96px;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

@supports not (backdrop-filter: blur(4px)) {
  .footer {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.footer--item {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .footer {
    padding: 48px;
  }
}

@media (max-width: 667px) {
  .footer {
    padding: 24px;
  }
}
