.navigation {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  padding: 96px;
  box-sizing: border-box;
  opacity: 0;
  transition: visibility 0s 0.4s, opacity 0.4s;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
}

.navigation--open {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.4s;
}

.navigation ul {
  list-style-type: none;
  text-align: center;
}

.navigation li {
  margin-bottom: 48px;
}

.navigation a {
  color: #04d98b;
  border: none;
  font-size: 48pt;
  font-weight: 600;
}

.navigation a.active {
  color: #3d278c;
}

.navigation a:hover {
  color: #5321bf;
}

.subnavigation {
  display: flex;
  align-items: flex-start;
  max-width: 1024px;
}

.subnavigation .caption {
  margin-right: 24px;
  margin-bottom: 24px;
}

.subnavigation ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.subnavigation li {
  margin: 0px 24px 24px 0px;
}

.subnavigation button {
  background-color: #f2f2f2;
  color: #04d98b;
  border: none;
  padding: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;
}

.subnavigation button.active,
.subnavigation button:hover {
  color: white;
  background-color: #04d98b;
}

@media (max-width: 1024px) {
  .navigation {
    padding: 48px;
  }

  .navigation a {
    font-size: 24pt;
  }
}

@media (max-width: 414px) {
  .subnavigation .caption {
    display: none;
  }

  .subnavigation li {
    margin-right: 12px;
    margin-bottom: 12px;
  }

  .subnavigation button {
    padding: 12px;
  }
}

@media (max-width: 375px) {
  .navigation {
    padding: 24px;
  }

  .navigation a {
    font-size: 18pt;
  }

  .navigation li {
    margin-bottom: 24px;
  }
}
