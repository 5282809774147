.button--navigation {
  position: fixed;
  top: 88px;
  right: 88px;
  z-index: 2;
  background: none;
  border: none;
  border-radius: 2px;
  padding: 8px;
  cursor: pointer;
  fill: #04d98b;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

@supports not (backdrop-filter: blur(4px)) {
  .button--navigation {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.button--navigation svg {
  width: 72px;
}

@media (max-width: 1024px) {
  .button--navigation {
    top: 48px;
    right: 48px;
  }

  .button--navigation svg {
    width: 48px;
  }
}

@media (max-width: 667px) {
  .button--navigation {
    top: 16px;
    right: 16px;
  }

  .button--navigation svg {
    width: 36px;
  }
}
