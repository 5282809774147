.list {
  list-style-type: none;
}

.list__item {
  margin-bottom: 24px;
  line-height: 1.5em;
  display: flex;
}

.list__item__year {
  min-width: 128px;
}

.list__item__text {
  display: inline;
}

.list__item__text a {
  border: none;
  text-decoration: none;
}

.list__item__text__link {
  text-decoration: none;
  border: none;
  background-color: #f2f2f2;
  display: inline-flex;
  margin-left: 12px;
  padding: 0px 4px;
  border-radius: 4px;
}

.list__item__text__link svg {
  height: 18px;
}

.list__item__text__link:hover {
  border: none;
  background-color: #04d98b;
  color: #f2f2f2;
}

.list__item__text__link:hover svg {
  stroke: #f2f2f2;
}

@media (max-width: 375px) {
  .list__item__year {
    min-width: 64px;
    max-width: 64px;
  }
}
