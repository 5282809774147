.landing-page__content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: 96px 52px 1fr;
  grid-template-rows: 96px 52px 1fr;
  padding: 0px 96px;
  overflow: hidden;
  height: 100vh;
}

.landing-page h2 {
  margin-bottom: 72px;
}

.landing-page__logo {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}

.landing-page__logo img {
  height: 100%;
}

_:-ms-fullscreen,
:root .landing-page__logo {
  height: auto;
}

.landing-page__text {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 3;
  grid-row: 3;
  margin-top: 48px;
}

.landing-page__text h2 {
  line-height: 1.2em;
}

.landing-page__image {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  grid-row: 1 / span 3;
  margin-right: 164px;
  height: 100vh;
}

.landing-page .footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 96px;
}

.landing-page__aktuell {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 3;
  grid-row: 3;
}

@media (max-width: 420px) {
  .landing-page__image {
    display: none;
  }
}

@media (max-height: 568px) {
  .landing-page__content {
    height: auto;
  }

  .landing-page .footer {
    position: relative;
  }
}

@media (max-width: 1024px) {
  .landing-page__content {
    -ms-grid-rows: 48px 48px 1fr;
    grid-template-rows: 48px 48px 1fr;
    padding: 0px 48px;
  }

  .landing-page__image {
    margin-right: 128px;
  }

  .landing-page .footer {
    padding: 24px 48px;
  }
}

@media (max-width: 667px) {
  .landing-page__content {
    -ms-grid-rows: 24px 36px 1fr;
    grid-template-rows: 24px 36px 1fr;
    padding: 0px 24px;
  }

  .landing-page .footer {
    padding: 24px;
  }
}
